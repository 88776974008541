<template>
  <SimpleHeader></SimpleHeader>

  <div class="standings" :class="{'container': fullWidth}">
      <!-- desktop ranking table  v-if="!isMobile"    -->
      <!-- <div class="match-table__wrapper"> -->
        <!-- <div v-show="rankingTable.isLoading" class="skeleton-table__wrapper">
          <div class="display-flex-sb mb-1rem" v-for="(o, index) in 10" :key="index">
              <div class="skeleton-child skeleton-table__left"></div>
              <div class="skeleton-child skeleton-table__right"></div>
          </div>
        </div> -->

        <!-- <Loading v-show="rankingTable.isLoading"></Loading> -->
        <!-- <MatchTable
          :isShowTabs="false"
          :type="'total'"
          :isWorldCup="localIsWorldCup"
          :isSpecialEvent="localIsSpecialEvent"
          :showTableHeader="showTableHeader"
          :matchTableListData="matchTableList"
          v-show="!rankingTable.isLoading && rankingTable.isShow"
        ></MatchTable> -->

        <!-- v-show="!rankingTable.isLoading && rankingTable.isShow"
        :matchTableListData="matchTableList" -->
        <MultiRankingTable
          :isShowTabs="false"
          :type="'total'"
        ></MultiRankingTable>
      <!-- </div> -->
      <!-- end desktop ranking table -->
      <!-- <div v-html="theEventAbout" class="world-cup-about"></div> -->
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import SimpleHeader from "@/components/header/SimpleHeader.vue";
import about from "@/js/about/world-cup-2022.js";
import afcAbout from "@/js/about/afc-2023.js";
import afconAbout from "@/js/about/afcon-2023.js";
// import MatchTable from "@/components/match/football/Table.vue";
import MultiRankingTable from "@/components/match/football/MultiRankingTable.vue";

export default {
name: "Standings",
components: {
  SimpleHeader,
  // MatchTable,
  MultiRankingTable,
},
props: {
  isWorldCup: {
    type: Boolean,
    default: false,
  },
  fullWidth: {
    type: Boolean,
    default: true,
  },
  showTableHeader: {
    type: Boolean,
    default: false,
  },
  eventAbout: {
    type: String,
  },
},
data() {
  return {
      matchTableList: [],
      rankingTable: {
          isShow: false,
          isShowArrow: true,
          isLoading: false,
          currentSelectedLeagueIndex: 0,
          list: [],
          currentSelectedLeague: "",
      },
      localIsWorldCup: false,
      localIsSpecialEvent: false,
      theEventAbout: "",
  };
},
computed: {
  ...mapGetters([
    "isMobile",
    "currentLocale",
    "currentSportType",
    "userInfo",
    "isLogin",
  ]),
},
watch: {
  currentLocale(newVal, oldVal) {
    this.init();
  }
},
mounted() {
  this.init();
},
methods: {
  ...mapActions(["getSoccerLeagueRanking"]),
  init() {
      // this.getSoccerLeagueRankingData();
      // this.getAboutInfo();
  },
  async getSoccerLeagueRankingData(param) {
    this.rankingTable.isShow= true;
    this.rankingTable.isLoading = true;
    let params = {
      leagueIdList: this.$route.params.leagueId ?? '',
      countryId: this.$route.meta.countryId,
      // groupNameEn: "Group", // special for AFC, AFCON 2023
    };
    
    if (this.isWorldCup) {// 75
      this.localIsWorldCup = true;
    } else if (this.$route.params.leagueId == 93 || this.$route.params.leagueId == 95) {
      this.localIsSpecialEvent = true;
    }
    const result = await this.getSoccerLeagueRanking(params);
    this.getLeagueListForRankingTable(result.result);
    this.rankingTable.isLoading = false;
  },

  //handle ranking table
  getLeagueListForRankingTable(result) {
    if (result.length === 0) {
      this.rankingTable.isShow = false;
    } else {
      this.rankingTable.isShow = true;
      this.rankingTable.list = result;
      this.matchTableList = result;
      // this.handleLeagueListRankingTable();
    }
  },

  getAboutInfo() {
    if (typeof about[this.currentLocale] !== "undefined" && this.$route.params.leagueId === "75") {
      this.theEventAbout = about[this.currentLocale];
    } else if (typeof afcAbout[this.currentLocale] !== "undefined" && this.$route.params.leagueId === "95") {
      this.theEventAbout = afcAbout[this.currentLocale];
    } else if (typeof afconAbout[this.currentLocale] !== "undefined" && this.$route.params.leagueId === "93") {
      this.theEventAbout = afconAbout[this.currentLocale];
    }
  },
},
};
</script>

<style scoped>
.world-cup-about {
margin: 1.156rem 0 3rem;
color: #CFD9DE;
font-size: 0.688rem;
}
.world-cup-about >>> p {
margin-bottom: 8px;
}
@media (max-width: 768px)  {
  .standings {
    padding-top: 4.5rem;
  }
}
</style>
