<template>
  <div class="match-table__wrapper">
    <div class="match-table__title-wrapper" v-show="rankingTable.isShow">
      <!-- <div>{{ $t("STANDINGS") }}</div> -->
      <div class="display-flex-sb">
        <div
          class="match-table__title-arrow__wrapper tl"
          :class="{ invisible: !rankingTable.isShowArrow }"
          @click="handleLeagueListRankingTable('prev')"
        >
          <svg
            width="6"
            height="10"
            viewBox="0 0 6 10"
            class="match-table__title-arrow__icon"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M5.66542 1.74875L2.42125 5L5.66542 8.25125L4.66667 9.25L0.41667 5L4.66667 0.75L5.66542 1.74875Z"
            />
          </svg>
        </div>
        <div class="match-table__title-country-container">{{ rankingTable.currentSelectedLeague }}</div>
        <div
          class="match-table__title-arrow__wrapper"
          :class="{ invisible: !rankingTable.isShowArrow }"
          @click="handleLeagueListRankingTable('next')"
        >
          <svg
            width="6"
            height="10"
            viewBox="0 0 6 10"
            v-show="rankingTable.isShowArrow"
            class="match-table__title-arrow__icon"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0.334961 8.46017L3.57913 5.20892L0.334961 1.95767L1.33371 0.958923L5.58371 5.20892L1.33371 9.45892L0.334961 8.46017Z"
            />
          </svg>
        </div>
      </div>
    </div>
    <div v-show="rankingTable.isLoading" class="skeleton-table__wrapper">
      <div
        class="display-flex-sb mb-1rem"
        v-for="(o, index) in 10"
        :key="index"
      >
        <div class="skeleton-child skeleton-table__left"></div>
        <div class="skeleton-child skeleton-table__right"></div>
      </div>
    </div>
    <!-- <Loading v-show="rankingTable.isLoading"></Loading> -->
    <MatchTable
      :isShowTabs="false"
      :type="'total'"
      :matchTableListData="matchTableList"
      v-show="!rankingTable.isLoading && rankingTable.isShow"
    ></MatchTable>
    <!-- <StickyAppBanner pos="isSticky" /> -->
  </div>
</template>

<script>
import { mapActions } from "vuex";
import MatchTable from "@/components/match/football/Table.vue";
export default {
  components: { MatchTable },
  mounted() {
    this.init();
  },
  data() {
    return {
      matchTableList: [],
      countryId: this.$route.meta.countryId,
      rankingTable: {
        isShow: false,
        isShowArrow: true,
        isLoading: false,
        currentSelectedLeagueIndex: 0,
        list: [],
        currentSelectedLeague: "",
      },
    };
  },
  methods: {
    ...mapActions(["getSoccerLeagueRanking"]),
    init() {
      this.getSoccerLeagueRankingData();
    },
    async getSoccerLeagueRankingData(param) {
      //this.rankingTable.isShow= true;
      this.rankingTable.isLoading = true;
      let params = {
        leagueIdList: "",
        countryId: this.countryId,
      };
      const result = await this.getSoccerLeagueRanking(params);
      this.getLeagueListForRankingTable(result.result);
      this.rankingTable.isLoading = false;
    },
    //handle ranking table
    getLeagueListForRankingTable(result) {
      if (result.length === 0) {
        this.rankingTable.isShow = false;
      } else {
        this.rankingTable.isShow = true;
        this.rankingTable.list = result;
        this.handleLeagueListRankingTable();
      }
    },

    handleLeagueListRankingTable(action) {
      let limit = this.rankingTable.list.length - 1;
      let index = 0;
      if (limit === 0) {
        this.rankingTable.isShowArrow = false;
      } else {
        this.rankingTable.isShowArrow = true;
      }
      if (action === "next") {
        if (this.rankingTable.currentSelectedLeagueIndex < limit) {
          this.rankingTable.currentSelectedLeagueIndex++;
        } else {
          this.rankingTable.currentSelectedLeagueIndex = 0;
        }
        index = this.rankingTable.currentSelectedLeagueIndex;
      } else if (action === "prev") {
        if (this.rankingTable.currentSelectedLeagueIndex > 0) {
          this.rankingTable.currentSelectedLeagueIndex--;
        } else {
          this.rankingTable.currentSelectedLeagueIndex = limit;
        }

        index = this.rankingTable.currentSelectedLeagueIndex;
      } else {
        index = 0;
      }

      this.rankingTable.currentSelectedLeague =
        this.rankingTable.list[index].tableName;
      this.matchTableList = this.rankingTable.list[index];
    },
  },
};
</script>

<style scoped>
.match-table__title-wrapper {
  /* display: flex;
  justify-content: center;
  align-items: center; */
  font-size: 0.88rem;
  margin-bottom: 0.5rem;
}

.match-table__main-group-stage-title {
  font-size: 1.125rem;
  font-weight: 700;
  margin-bottom: 0.5rem;
}
.match-table__header-country__wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 0.88rem;
  margin-bottom: 0.4rem;
}
.match-table__header {
  background-color: #212b30;
}
.match-table-wc__header {
  background-color: #212b30;
  font-weight: 700;
  font-size: 0.875rem;
  padding: 0.875rem 0.75rem;
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
}
.match-table-wc__wrapper {
  margin-bottom: 1.5rem;
}
.match-table-wc__child-wrapper .match-table {
  border-bottom: 0;
  position: relative;
}
.match-table-wc__child-wrapper .match-table-row::after {
  content: "";
  width: calc(100% - 1.6rem);
  border-bottom: 1px solid #212b30;
  position: absolute;
  bottom: 0;
}
.match-table-wc__child-wrapper
  .match-table-row-wrapper
  a:last-of-type
  .match-table-row::after {
  border-bottom: none;
}
.match-table-wc__child-wrapper .match-table-row:hover::after {
  border-bottom: 1px solid var(--bg-hover);
}
.match-table {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #cfd9de;
  border-bottom: 1px solid #212b30;
  padding: 0.5rem 0.8rem;
  font-size: 0.6875rem;
}

.match-table.selected {
  background-color: #212b30;
  color: white;
  font-weight: 700;
}
.match-table:hover {
  background-color: var(--bg-hover);
}
.match-table__index {
  margin-right: 0.3rem;
  width: 1.1rem;
}
.color-1 {
  color: #5cd66c;
}
.color-2 {
  color: #2e8fef;
}
.color-3 {
  color: #f7eb83;
}
.color-4 {
  color: #ff9800;
}
.color-5 {
  color: #673ab7;
}
.color-p {
  color: #ffc0cb;
}
.color-r {
  color: #d81f0d;
}
.color-sp-1 {
  color: #ed9c5a;
}
.match-table__club {
  display: flex;
  align-items: center;
  flex: 2;
  max-width: 8rem;
}

.match-table__club-icon {
  margin-right: 0.5rem;
}
.match-table__score {
  display: flex;
  width: 10rem;
  justify-content: space-between;
  text-align: center;
}
.match-table__score > div {
  flex: 1;
  margin: 0 0.15rem;
  min-width: 1.01rem;
}
.match-table__tips-wrapper {
  background-color: var(--color-theme);
  color: #cfd9de;
}
.match-table__tips {
  display: flex;
  flex-direction: row;
  margin: 0 0.8rem;
  padding: 0.5rem 0;
  font-size: 0.7rem;
}
.match-table__title-arrow__wrapper {
  width: 2rem;
  text-align: right;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}
.match-table__title-arrow__icon {
  fill: white;
}
.match-table__title-arrow__wrapper:hover .match-table__title-arrow__icon {
  fill: var(--color-hover);
}
.match-table__title-arrow__wrapper.tl {
  text-align: left;
}
@media (max-width: 768px) {
  .match-table__main-group-stage-title {
    font-size: 0.875rem;
  }
  .match-table__title-country-container {
    text-align: center;
  }
}
</style>
